.currencies-filter {
    background-color: none;
    width: 50rem;
    color: white;
    padding: 0 1rem;
    margin: auto auto;
  }
  
  .currencies-filter__control {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
  }
  
  .currencies-filter label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .currencies-filter select {
    font: inherit;
    padding: 0.5rem 3rem;
    font-weight: bold;
    border-radius: 6px;
  }