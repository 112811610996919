@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f;
}


.card{
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}
.currency-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin: 1rem 0;
    background-color: #4a7791;
  }
  
  .currency-item__box {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: flex-end;
    flex-flow: column-reverse;
    justify-content: flex-start;
    flex: 1 1;
  }
  
  .currency-item h2 {
    color: #3a3a3a;
    font-size: 1rem;
    flex: 1 1;
    margin: 0 1rem;
    color: white;
  }
  
  .currency-item__data {
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #559b70;
    border: 1px solid white;
    padding: 0.5rem;
    border-radius: 12px;
  }

  .calculate {
    font: inherit;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 1px solid white;
    background-color: #89acc4;
    color: white;
    border-radius: 12px;
    margin-right: 1rem;
  }
  
  @media (min-width: 580px) {
    .currency-item__box {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex: 1 1;
    }
  
    .currency-item__box h2 {
      font-size: 1.25rem;
    }
  
    .currency-item__data {
      font-size: 1.25rem;
      padding: 0.5rem 1.5rem;
    }
  }




.currencies-list {
  list-style: none;
  padding: 0;
}

.currencies-list__fallback {
  color: white;
  text-align: center;
}

.calculated_value_holder{
  background-color: #2d5773;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.calculated_value_holder .amount{
  color: white;
}

.calculated_value_holder .code{
  color: lightgreen;
  font-weight: bold;
}

.currencies {
    padding: 1rem;
    background-color: #89acc4;
    margin: 2rem auto;
    width: 50rem;
    max-width: 95%;
  }

.currencies-filter {
    background-color: none;
    width: 50rem;
    color: white;
    padding: 0 1rem;
    margin: auto auto;
  }
  
  .currencies-filter__control {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
  }
  
  .currencies-filter label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .currencies-filter select {
    font: inherit;
    padding: 0.5rem 3rem;
    font-weight: bold;
    border-radius: 6px;
  }
