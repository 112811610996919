.currencies-list {
  list-style: none;
  padding: 0;
}

.currencies-list__fallback {
  color: white;
  text-align: center;
}

.calculated_value_holder{
  background-color: #2d5773;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.calculated_value_holder .amount{
  color: white;
}

.calculated_value_holder .code{
  color: lightgreen;
  font-weight: bold;
}